<template>
  <v-card tile flat class="blue-grey lighten-5">
    <v-card-title>Organization Profile</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Your Logo</v-col>
          <v-col cols="4" class="ml-3">
            <v-file-input
              v-if="(logoSrc == '' || logoSrc == null)"
              ref="logofile"
              label="Upload your logo"
              prepend-icon="false"
              append-icon="attach_file"
              show-size
              counter
              accept="image/*"
              outlined
              dense
              @change="onLogoChange"
            ></v-file-input>
            <v-img
              v-else
              :src="logoSrc"
              aspect-ratio="1"
              class="grey lighten-2"
              max-width="500"
              max-height="60"
            ></v-img>
          </v-col>
          <v-col cols="4" class="ml-4 caption align-self-center">
            <span>This logo will appear on the documents (invoices, etc.) that are created.</span>
            <p class="mb-0">Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB.</p>
            <a class="blue--text" @click="removeLogo()">Remove logo</a>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Organization Name*</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field hide-details v-model="org.name" label="Name" outlined dense solo flat></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Description</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="org.description"
              label="Description"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Address</v-col>
          <v-col cols="6" class="ml-3">
            <v-text-field
              hide-details
              v-model="org.address.address"
              label="Street address"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2"></v-col>
          <v-col cols="3" class="ml-3">
            <v-text-field
              hide-details
              v-model="org.address.city"
              label="City"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-text-field
              hide-details
              v-model="org.address.state"
              label="State"
              placeholder="State"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2"></v-col>
          <v-col cols="3" class="ml-3">
            <v-text-field
              hide-details
              v-model="org.address.country"
              label="Country"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-text-field
              hide-details
              v-model="org.address.postal_code"
              label="Postal code"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Time Zone*</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="org.time_zone"
              label="Time zone"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn depressed color="green" class="white--text" @click="orgDetailsSave()">Save</v-btn>
      <v-btn depressed color="red" class="white--text" @click="onCancelClick()">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "org-profile",
  components: {},
  data() {
    return {
      org: {
        name: null,
        logo_file_name: null,
        description: null,
        address: {
          address: null,
          city: null,
          state: null,
          country: null,
          postal_code: null
        }
      },
      logoSrc: "",
      orgLogo: {},
      orgLogoName: ""
    };
  },
  computed: {
    orgDetails() {
      if (
        this.$store.getters["MDMStore/getterOrgDetails"].hasOwnProperty(
          "logo_file_name"
        )
      ) {
        this.logoSrc = "/mdm/public/" + this.org.logo_file_name;
      }
      return this.$store.getters["MDMStore/getterOrgDetails"];
    }
  },
  watch: {
    orgDetails() {
      Object.assign(this.org, this.$store.getters["MDMStore/getterOrgDetails"]);
      if (this.org.hasOwnProperty("logo_file_name")) {
        this.logoSrc = "/mdm/public/" + this.org.logo_file_name;
      }
    }
  },
  beforeCreate() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.$store.dispatch({
        type: "MDMStore/getOrgDetails"
      });
    });
  },
  mounted() {
    if (
      this.$store.getters["MDMStore/getterOrgDetails"].hasOwnProperty(
        "logo_file_name"
      )
    ) {
      this.logoSrc = "/mdm/public/" + this.org.logo_file_name;
    }
    Object.assign(this.org, this.$store.getters["MDMStore/getterOrgDetails"]);
  },
  methods: {
    removeLogo() {
      this.logoSrc = "";
    },
    onLogoChange($event) {
      let formData = new FormData();
      formData.append("logo", $event);
      this.orgLogo = formData;
      this.orgLogoName = $event.name; //Object.assign({}, formData);
      // this.file = formData;
    },
    async orgDetailsSave() {
      var self = this;
      const orgLogoSave = await axios.post(
        "/mdm/api/upsertOrgLogo",
        // "/upsertOrgLogo",
        this.orgLogo,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      if (
        orgLogoSave.data.hasOwnProperty("success") &&
        orgLogoSave.data.success
      ) {
        this.$store.dispatch({
          type: "MDMStore/updateOrgProfile",
          org: {
            id: localStorage.getItem("orgId"),
            name: self.org.name,
            description: self.org.description,
            logo_file_name: self.orgLogoName,
            address: {
              address: self.org.address.address,
              city: self.org.address.city,
              state: self.org.address.state,
              country: self.org.address.country,
              postal_code: self.org.address.postal_code
            }
          }
        });
      }
    },
    onCancelClick() {
      this.$router.go(-1)
    }
  }
};
</script>
